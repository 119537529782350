<div
    (click)="onClickHandler()"
    class="relative flex flex-wrap grow shrink items-center w-full max-w-[282px] h-auto cursor-pointer transition-all duration-300 ease-in-out {{
        isSelect
            ? 'border-primary-500 border-[1px] bg-primary-100 dark:border-white dark:border-[1px]'
            : 'border-[1px] bg-white'
    }} rounded-lg p-2 dark:bg-[#61718d] dark:border-[#4c596f] dark:border-[1px] dark:shadow-lg select-none"
>
    <img src="{{ data.icon }}" alt="crystals" class="size-6 xs:size-8" />
    <div class="flex flex-col justify-center ml-2">
        <p class="text-black text-sm dark:text-white font-semibold">{{ data.gift }} Coin</p>
        <p class="text-[#7E8594] text-xs font-medium dark:text-neutral-200">{{ data.currency }} {{ data.price }}</p>
    </div>

    <!-- POPULAR -->
    <div
        *ngIf="data.type === ProductTypes.POPULAR"
        class="absolute bottom-11 right-4 xs:bottom-10 xs:right-5 flex flex-wrap items-center justify-center bg-[#FF2D6C] px-[8px] rounded-[6px] xs:px-[10px] py-0.5 xs:py-1"
    >
        <p class="text-white text-[8px] xs:text-[10px] font-semibold">POPULAR</p>
    </div>

    <!-- BEST OFFER -->
    <div
        *ngIf="data.type === ProductTypes.BEST_OFFER"
        class="absolute bottom-11 right-4 xs:bottom-10 xs:right-5 flex flex-wrap items-center justify-center bg-[#6FDB68] rounded-[6px] px-[8px] py-0.5 xs:px-[10px] xs:py-1"
    >
        <p class="text-white text-[8px] xs:text-[10px] font-semibold">BEST OFFER</p>
    </div>
</div>
