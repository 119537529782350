import {Component, OnInit, inject} from '@angular/core';
import {LoginButtonComponent} from '@core/components/ui-components/login-button/login-button.component';
import {ModalCloseButtonComponent} from '@core/components/ui-components/modal-close-button/modal-close-button.component';
import {PurchaseCardComponent} from '@shared/components/purchase-card/purchase-card.component';

import {WebPackages} from '@core/models/purchase/web-packages';
import {NgForOf, NgIf} from '@angular/common';
import {PurchaseService} from '@core/services/purchase/purchase.service';
import {PurchaseFormResponse} from '@core/models/purchase/purchase-form-response';
import {ToastService} from '@core/services/ui/toast/toast.service';
import {TranslocoPipe} from '@ngneat/transloco';
import {ConstantService} from '@core/services/common/constant.service';
import {ModalService} from '@core/services/modal/modal.service';
import {PurchaseIframeComponent} from '@shared/components/purchase-iframe/purchase-iframe.component';

@Component({
    selector: 'app-purchase',
    standalone: true,
    imports: [LoginButtonComponent, ModalCloseButtonComponent, PurchaseCardComponent, NgForOf, NgIf, TranslocoPipe],
    templateUrl: './purchase.component.html',
    styleUrl: './purchase.component.scss',
})
export class PurchaseComponent implements OnInit {
    constantService = inject(ConstantService);
    packages: WebPackages[] = [];
    selectedPackage = 0;
    skeletonPackages = Array.from({length: 2});

    constructor(
        private purchaseService: PurchaseService,
        private toastService: ToastService,
        private modalService: ModalService,
    ) {}

    ngOnInit() {
        this.getPackages();
    }

    getPackages() {
        this.purchaseService.getPackages().subscribe((data: WebPackages[]) => {
            this.packages = data;
            this.selectedPackage = this.packages[0]?.id;
            this.skeletonPackages = [];
        });
    }

    onSelectPackage(id: number) {
        if (this.selectedPackage === id) {
            return;
        }
        this.selectedPackage = id;
    }

    onBuyButtonClick() {
        const packageId = this.packages.find(p => p.id === this.selectedPackage)?.packageId;
        if (this.selectedPackage === 0) {
            this.toastService.openToast({
                message: 'Please select a package to continue',
                duration: 3000,
                type: 'error',
                position: 'bottom-center',
            });
        }
        if (packageId) {
            this.purchaseService.createFormUrl(packageId).subscribe((response: PurchaseFormResponse) => {
                if (response) {
                    const newWindow = window.open(
                        response.formUrl,
                        '_blank', // <- This is what makes it open in a new window.
                    );
                    if (!newWindow) {
                        //show iframe
                        this.modalService.closeAndOpen(PurchaseIframeComponent, {data: {src: response.formUrl}});
                        this.toastService.openToast({
                            message: 'Please allow pop-ups for this site',
                            duration: 3000,
                            type: 'error',
                            position: 'bottom-center',
                        });
                    }
                }
            });
        }
    }
}
