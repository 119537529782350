import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ModalCloseButtonComponent} from '@core/components/ui-components/modal-close-button/modal-close-button.component';

@Component({
    selector: 'app-purchase-iframe',
    standalone: true,
    imports: [ModalCloseButtonComponent],
    templateUrl: './purchase-iframe.component.html',
    styleUrl: './purchase-iframe.component.scss',
})
export class PurchaseIframeComponent implements OnInit {
    src!: string;
    safeUrl!: SafeResourceUrl;
    constructor(public sanitizer: DomSanitizer) {}
    ngOnInit() {
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
    }
}
