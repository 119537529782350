import { browserExt } from './environment-browser/browserExt.mjs';
import { webworkerExt } from './environment-webworker/webworkerExt.mjs';
import { extensions } from './extensions/Extensions.mjs';
export { ExtensionType, normalizeExtensionPriority } from './extensions/Extensions.mjs';
import './rendering/init.mjs';
import './spritesheet/init.mjs';
import './accessibility/index.mjs';
import './advanced-blend-modes/index.mjs';
import './app/index.mjs';
import './assets/index.mjs';
import './color/index.mjs';
import './compressed-textures/index.mjs';
import './culling/index.mjs';
import './environment/index.mjs';
import './environment-browser/index.mjs';
import './environment-webworker/index.mjs';
import './events/index.mjs';
import './extensions/index.mjs';
import './filters/index.mjs';
import './maths/index.mjs';
import './prepare/index.mjs';
import './rendering/index.mjs';
import './scene/index.mjs';
import './spritesheet/index.mjs';
import './ticker/index.mjs';
import './utils/index.mjs';
export { AccessibilitySystem } from './accessibility/AccessibilitySystem.mjs';
export { accessibilityTarget } from './accessibility/accessibilityTarget.mjs';
export { ColorBlend } from './advanced-blend-modes/ColorBlend.mjs';
export { ColorBurnBlend } from './advanced-blend-modes/ColorBurnBlend.mjs';
export { ColorDodgeBlend } from './advanced-blend-modes/ColorDodgeBlend.mjs';
export { DarkenBlend } from './advanced-blend-modes/DarkenBlend.mjs';
export { DifferenceBlend } from './advanced-blend-modes/DifferenceBlend.mjs';
export { DivideBlend } from './advanced-blend-modes/DivideBlend.mjs';
export { ExclusionBlend } from './advanced-blend-modes/ExclusionBlend.mjs';
export { HardLightBlend } from './advanced-blend-modes/HardLightBlend.mjs';
export { HardMixBlend } from './advanced-blend-modes/HardMixBlend.mjs';
export { LightenBlend } from './advanced-blend-modes/LightenBlend.mjs';
export { LinearBurnBlend } from './advanced-blend-modes/LinearBurnBlend.mjs';
export { LinearDodgeBlend } from './advanced-blend-modes/LinearDodgeBlend.mjs';
export { LinearLightBlend } from './advanced-blend-modes/LinearLightBlend.mjs';
export { LuminosityBlend } from './advanced-blend-modes/LuminosityBlend.mjs';
export { NegationBlend } from './advanced-blend-modes/NegationBlend.mjs';
export { OverlayBlend } from './advanced-blend-modes/OverlayBlend.mjs';
export { PinLightBlend } from './advanced-blend-modes/PinLightBlend.mjs';
export { SaturationBlend } from './advanced-blend-modes/SaturationBlend.mjs';
export { SoftLightBlend } from './advanced-blend-modes/SoftLightBlend.mjs';
export { SubtractBlend } from './advanced-blend-modes/SubtractBlend.mjs';
export { VividLightBlend } from './advanced-blend-modes/VividLightBlend.mjs';
export { Application } from './app/Application.mjs';
export { ResizePlugin } from './app/ResizePlugin.mjs';
export { TickerPlugin } from './app/TickerPlugin.mjs';
export { Assets, AssetsClass } from './assets/Assets.mjs';
export { BackgroundLoader } from './assets/BackgroundLoader.mjs';
export { Cache } from './assets/cache/Cache.mjs';
export { cacheTextureArray } from './assets/cache/parsers/cacheTextureArray.mjs';
export { detectAvif } from './assets/detections/parsers/detectAvif.mjs';
export { detectDefaults } from './assets/detections/parsers/detectDefaults.mjs';
export { detectMp4 } from './assets/detections/parsers/detectMp4.mjs';
export { detectOgv } from './assets/detections/parsers/detectOgv.mjs';
export { detectWebm } from './assets/detections/parsers/detectWebm.mjs';
export { detectWebp } from './assets/detections/parsers/detectWebp.mjs';
export { testImageFormat } from './assets/detections/utils/testImageFormat.mjs';
export { testVideoFormat } from './assets/detections/utils/testVideoFormat.mjs';
export { Loader } from './assets/loader/Loader.mjs';
export { LoaderParserPriority } from './assets/loader/parsers/LoaderParser.mjs';
export { loadJson } from './assets/loader/parsers/loadJson.mjs';
export { loadTxt } from './assets/loader/parsers/loadTxt.mjs';
export { getFontFamilyName, loadWebFont } from './assets/loader/parsers/loadWebFont.mjs';
export { loadSvg } from './assets/loader/parsers/textures/loadSVG.mjs';
export { loadImageBitmap, loadTextures } from './assets/loader/parsers/textures/loadTextures.mjs';
export { crossOrigin, determineCrossOrigin, loadVideoTextures, preloadVideo } from './assets/loader/parsers/textures/loadVideoTextures.mjs';
export { createTexture } from './assets/loader/parsers/textures/utils/createTexture.mjs';
export { WorkerManager } from './assets/loader/workers/WorkerManager.mjs';
export { resolveJsonUrl } from './assets/resolver/parsers/resolveJsonUrl.mjs';
export { resolveTextureUrl } from './assets/resolver/parsers/resolveTextureUrl.mjs';
export { Resolver, getUrlExtension } from './assets/resolver/Resolver.mjs';
export { checkDataUrl } from './assets/utils/checkDataUrl.mjs';
export { checkExtension } from './assets/utils/checkExtension.mjs';
export { convertToList } from './assets/utils/convertToList.mjs';
export { copySearchParams } from './assets/utils/copySearchParams.mjs';
export { createStringVariations } from './assets/utils/createStringVariations.mjs';
export { isSingleItem } from './assets/utils/isSingleItem.mjs';
export { Color } from './color/Color.mjs';
export { detectBasis } from './compressed-textures/basis/detectBasis.mjs';
export { loadBasis } from './compressed-textures/basis/loadBasis.mjs';
export { createLevelBuffers } from './compressed-textures/basis/utils/createLevelBuffers.mjs';
export { gpuFormatToBasisTranscoderFormat } from './compressed-textures/basis/utils/gpuFormatToBasisTranscoderFormat.mjs';
export { basisTranscoderUrls, setBasisTranscoderPath } from './compressed-textures/basis/utils/setBasisTranscoderPath.mjs';
export { loadBasisOnWorker } from './compressed-textures/basis/worker/loadBasisOnWorker.mjs';
export { DDS, DXGI_TO_TEXTURE_FORMAT, FOURCC_TO_TEXTURE_FORMAT, TEXTURE_FORMAT_BLOCK_SIZE } from './compressed-textures/dds/const.mjs';
export { loadDDS } from './compressed-textures/dds/loadDDS.mjs';
export { parseDDS } from './compressed-textures/dds/parseDDS.mjs';
export { loadKTX } from './compressed-textures/ktx/loadKTX.mjs';
export { parseKTX } from './compressed-textures/ktx/parseKTX.mjs';
export { GL_INTERNAL_FORMAT, KTX } from './compressed-textures/ktx2/const.mjs';
export { loadKTX2 } from './compressed-textures/ktx2/loadKTX2.mjs';
export { convertFormatIfRequired } from './compressed-textures/ktx2/utils/convertFormatIfRequired.mjs';
export { createLevelBuffersFromKTX } from './compressed-textures/ktx2/utils/createLevelBuffersFromKTX.mjs';
export { getTextureFormatFromKTXTexture } from './compressed-textures/ktx2/utils/getTextureFormatFromKTXTexture.mjs';
export { glFormatToGPUFormat } from './compressed-textures/ktx2/utils/glFormatToGPUFormat.mjs';
export { gpuFormatToKTXBasisTranscoderFormat } from './compressed-textures/ktx2/utils/gpuFormatToKTXBasisTranscoderFormat.mjs';
export { ktxTranscoderUrls, setKTXTranscoderPath } from './compressed-textures/ktx2/utils/setKTXTranscoderPath.mjs';
export { vkFormatToGPUFormat } from './compressed-textures/ktx2/utils/vkFormatToGPUFormat.mjs';
export { loadKTX2onWorker } from './compressed-textures/ktx2/worker/loadKTX2onWorker.mjs';
export { detectCompressed } from './compressed-textures/shared/detectCompressed.mjs';
export { resolveCompressedTextureUrl, validFormats } from './compressed-textures/shared/resolveCompressedTextureUrl.mjs';
export { Culler } from './culling/Culler.mjs';
export { CullerPlugin } from './culling/CullerPlugin.mjs';
export { cullingMixin } from './culling/cullingMixin.mjs';
export { DOMAdapter } from './environment/adapter.mjs';
export { autoDetectEnvironment } from './environment/autoDetectEnvironment.mjs';
export { BrowserAdapter } from './environment-browser/BrowserAdapter.mjs';
export { WebWorkerAdapter } from './environment-webworker/WebWorkerAdapter.mjs';
export { EventBoundary } from './events/EventBoundary.mjs';
export { EventSystem } from './events/EventSystem.mjs';
export { EventsTicker } from './events/EventTicker.mjs';
export { FederatedEvent } from './events/FederatedEvent.mjs';
export { FederatedContainer } from './events/FederatedEventTarget.mjs';
export { FederatedMouseEvent } from './events/FederatedMouseEvent.mjs';
export { FederatedPointerEvent } from './events/FederatedPointerEvent.mjs';
export { FederatedWheelEvent } from './events/FederatedWheelEvent.mjs';
export { default as blendTemplateFrag } from './filters/blend-modes/blend-template.frag.mjs';
export { default as blendTemplateVert } from './filters/blend-modes/blend-template.vert.mjs';
export { default as blendTemplateWgsl } from './filters/blend-modes/blend-template.wgsl.mjs';
export { default as hslWgsl } from './filters/blend-modes/hsl.wgsl.mjs';
export { default as alphaFrag } from './filters/defaults/alpha/alpha.frag.mjs';
export { default as alphaWgsl } from './filters/defaults/alpha/alpha.wgsl.mjs';
export { default as blurTemplateWgsl } from './filters/defaults/blur/gpu/blur-template.wgsl.mjs';
export { default as colorMatrixFilterFrag } from './filters/defaults/color-matrix/colorMatrixFilter.frag.mjs';
export { default as colorMatrixFilterWgsl } from './filters/defaults/color-matrix/colorMatrixFilter.wgsl.mjs';
export { default as defaultFilterVert } from './filters/defaults/defaultFilter.vert.mjs';
export { default as displacementFrag } from './filters/defaults/displacement/displacement.frag.mjs';
export { default as displacementVert } from './filters/defaults/displacement/displacement.vert.mjs';
export { default as displacementWgsl } from './filters/defaults/displacement/displacement.wgsl.mjs';
export { default as noiseFrag } from './filters/defaults/noise/noise.frag.mjs';
export { default as noiseWgsl } from './filters/defaults/noise/noise.wgsl.mjs';
export { default as maskFrag } from './filters/mask/mask.frag.mjs';
export { default as maskVert } from './filters/mask/mask.vert.mjs';
export { default as maskWgsl } from './filters/mask/mask.wgsl.mjs';
export { BlendModeFilter } from './filters/blend-modes/BlendModeFilter.mjs';
export { hslgl } from './filters/blend-modes/hls/GLhls.mjs';
export { hslgpu } from './filters/blend-modes/hls/GPUhls.mjs';
export { AlphaFilter } from './filters/defaults/alpha/AlphaFilter.mjs';
export { BlurFilter } from './filters/defaults/blur/BlurFilter.mjs';
export { BlurFilterPass } from './filters/defaults/blur/BlurFilterPass.mjs';
export { GAUSSIAN_VALUES } from './filters/defaults/blur/const.mjs';
export { generateBlurFragSource } from './filters/defaults/blur/gl/generateBlurFragSource.mjs';
export { generateBlurGlProgram } from './filters/defaults/blur/gl/generateBlurGlProgram.mjs';
export { generateBlurVertSource } from './filters/defaults/blur/gl/generateBlurVertSource.mjs';
export { generateBlurProgram } from './filters/defaults/blur/gpu/generateBlurProgram.mjs';
export { ColorMatrixFilter } from './filters/defaults/color-matrix/ColorMatrixFilter.mjs';
export { DisplacementFilter } from './filters/defaults/displacement/DisplacementFilter.mjs';
export { NoiseFilter } from './filters/defaults/noise/NoiseFilter.mjs';
export { Filter } from './filters/Filter.mjs';
export { FilterEffect } from './filters/FilterEffect.mjs';
export { FilterPipe } from './filters/FilterPipe.mjs';
export { FilterSystem } from './filters/FilterSystem.mjs';
export { MaskFilter } from './filters/mask/MaskFilter.mjs';
export { groupD8 } from './maths/matrix/groupD8.mjs';
export { Matrix } from './maths/matrix/Matrix.mjs';
export { DEG_TO_RAD, PI_2, RAD_TO_DEG } from './maths/misc/const.mjs';
export { isPow2, log2, nextPow2 } from './maths/misc/pow2.mjs';
export { squaredDistanceToLineSegment } from './maths/misc/squaredDistanceToLineSegment.mjs';
export { ObservablePoint } from './maths/point/ObservablePoint.mjs';
export { Point } from './maths/point/Point.mjs';
export { Circle } from './maths/shapes/Circle.mjs';
export { Ellipse } from './maths/shapes/Ellipse.mjs';
export { Polygon } from './maths/shapes/Polygon.mjs';
export { Rectangle } from './maths/shapes/Rectangle.mjs';
export { RoundedRectangle } from './maths/shapes/RoundedRectangle.mjs';
export { Triangle } from './maths/shapes/Triangle.mjs';
export { PrepareBase } from './prepare/PrepareBase.mjs';
export { PrepareQueue } from './prepare/PrepareQueue.mjs';
export { PrepareSystem } from './prepare/PrepareSystem.mjs';
export { PrepareUpload } from './prepare/PrepareUpload.mjs';
export { GlBatchAdaptor } from './rendering/batcher/gl/GlBatchAdaptor.mjs';
export { BatchGeometry } from './rendering/batcher/gpu/BatchGeometry.mjs';
export { generateGPULayout } from './rendering/batcher/gpu/generateGPULayout.mjs';
export { generateLayout } from './rendering/batcher/gpu/generateLayout.mjs';
export { getTextureBatchBindGroup } from './rendering/batcher/gpu/getTextureBatchBindGroup.mjs';
export { GpuBatchAdaptor } from './rendering/batcher/gpu/GpuBatchAdaptor.mjs';
export { Batch, Batcher } from './rendering/batcher/shared/Batcher.mjs';
export { BatcherPipe } from './rendering/batcher/shared/BatcherPipe.mjs';
export { BatchTextureArray } from './rendering/batcher/shared/BatchTextureArray.mjs';
export { MAX_TEXTURES } from './rendering/batcher/shared/const.mjs';
export { compileHighShaderGlProgram, compileHighShaderGpuProgram } from './rendering/high-shader/compileHighShaderToProgram.mjs';
export { compileHighShader, compileHighShaderGl } from './rendering/high-shader/compiler/compileHighShader.mjs';
export { addBits } from './rendering/high-shader/compiler/utils/addBits.mjs';
export { compileHooks, findHooksRx } from './rendering/high-shader/compiler/utils/compileHooks.mjs';
export { compileInputs } from './rendering/high-shader/compiler/utils/compileInputs.mjs';
export { compileOutputs } from './rendering/high-shader/compiler/utils/compileOutputs.mjs';
export { formatShader } from './rendering/high-shader/compiler/utils/formatShader.mjs';
export { injectBits } from './rendering/high-shader/compiler/utils/injectBits.mjs';
export { fragmentGPUTemplate, fragmentGlTemplate, vertexGPUTemplate, vertexGlTemplate } from './rendering/high-shader/defaultProgramTemplate.mjs';
export { colorBit, colorBitGl } from './rendering/high-shader/shader-bits/colorBit.mjs';
export { generateTextureBatchBit, generateTextureBatchBitGl } from './rendering/high-shader/shader-bits/generateTextureBatchBit.mjs';
export { globalUniformsBit, globalUniformsBitGl, globalUniformsUBOBitGl } from './rendering/high-shader/shader-bits/globalUniformsBit.mjs';
export { localUniformBit, localUniformBitGl, localUniformBitGroup2 } from './rendering/high-shader/shader-bits/localUniformBit.mjs';
export { roundPixelsBit, roundPixelsBitGl } from './rendering/high-shader/shader-bits/roundPixelsBit.mjs';
export { textureBit, textureBitGl } from './rendering/high-shader/shader-bits/textureBit.mjs';
export { AlphaMask } from './rendering/mask/alpha/AlphaMask.mjs';
export { AlphaMaskPipe } from './rendering/mask/alpha/AlphaMaskPipe.mjs';
export { ColorMask } from './rendering/mask/color/ColorMask.mjs';
export { ColorMaskPipe } from './rendering/mask/color/ColorMaskPipe.mjs';
export { MaskEffectManager, MaskEffectManagerClass } from './rendering/mask/MaskEffectManager.mjs';
export { ScissorMask } from './rendering/mask/scissor/ScissorMask.mjs';
export { StencilMask } from './rendering/mask/stencil/StencilMask.mjs';
export { StencilMaskPipe } from './rendering/mask/stencil/StencilMaskPipe.mjs';
export { addMaskBounds } from './rendering/mask/utils/addMaskBounds.mjs';
export { addMaskLocalBounds, getMatrixRelativeToParent } from './rendering/mask/utils/addMaskLocalBounds.mjs';
export { autoDetectRenderer } from './rendering/renderers/autoDetectRenderer.mjs';
export { BUFFER_TYPE } from './rendering/renderers/gl/buffer/const.mjs';
export { GlBuffer } from './rendering/renderers/gl/buffer/GlBuffer.mjs';
export { GlBufferSystem } from './rendering/renderers/gl/buffer/GlBufferSystem.mjs';
export { CLEAR } from './rendering/renderers/gl/const.mjs';
export { GlContextSystem } from './rendering/renderers/gl/context/GlContextSystem.mjs';
export { GlGeometrySystem } from './rendering/renderers/gl/geometry/GlGeometrySystem.mjs';
export { getGlTypeFromFormat } from './rendering/renderers/gl/geometry/utils/getGlTypeFromFormat.mjs';
export { GlBackBufferSystem } from './rendering/renderers/gl/GlBackBufferSystem.mjs';
export { GlColorMaskSystem } from './rendering/renderers/gl/GlColorMaskSystem.mjs';
export { GlEncoderSystem } from './rendering/renderers/gl/GlEncoderSystem.mjs';
export { GlRenderTarget } from './rendering/renderers/gl/GlRenderTarget.mjs';
export { GlStencilSystem } from './rendering/renderers/gl/GlStencilSystem.mjs';
export { GlUboSystem } from './rendering/renderers/gl/GlUboSystem.mjs';
export { GlRenderTargetAdaptor } from './rendering/renderers/gl/renderTarget/GlRenderTargetAdaptor.mjs';
export { GlRenderTargetSystem } from './rendering/renderers/gl/renderTarget/GlRenderTargetSystem.mjs';
export { batchSamplersUniformGroup } from './rendering/renderers/gl/shader/batchSamplersUniformGroup.mjs';
export { generateShaderSyncCode } from './rendering/renderers/gl/shader/GenerateShaderSyncCode.mjs';
export { GlProgram } from './rendering/renderers/gl/shader/GlProgram.mjs';
export { GlProgramData, IGLUniformData } from './rendering/renderers/gl/shader/GlProgramData.mjs';
export { GlShaderSystem } from './rendering/renderers/gl/shader/GlShaderSystem.mjs';
export { GlUniformGroupSystem } from './rendering/renderers/gl/shader/GlUniformGroupSystem.mjs';
export { migrateFragmentFromV7toV8 } from './rendering/renderers/gl/shader/migrateFragmentFromV7toV8.mjs';
export { compileShader } from './rendering/renderers/gl/shader/program/compileShader.mjs';
export { defaultValue } from './rendering/renderers/gl/shader/program/defaultValue.mjs';
export { ensureAttributes } from './rendering/renderers/gl/shader/program/ensureAttributes.mjs';
export { extractAttributesFromGlProgram } from './rendering/renderers/gl/shader/program/extractAttributesFromGlProgram.mjs';
export { generateProgram } from './rendering/renderers/gl/shader/program/generateProgram.mjs';
export { getMaxFragmentPrecision } from './rendering/renderers/gl/shader/program/getMaxFragmentPrecision.mjs';
export { getTestContext } from './rendering/renderers/gl/shader/program/getTestContext.mjs';
export { getUboData } from './rendering/renderers/gl/shader/program/getUboData.mjs';
export { getUniformData } from './rendering/renderers/gl/shader/program/getUniformData.mjs';
export { logProgramError } from './rendering/renderers/gl/shader/program/logProgramError.mjs';
export { mapSize } from './rendering/renderers/gl/shader/program/mapSize.mjs';
export { mapGlToVertexFormat, mapType } from './rendering/renderers/gl/shader/program/mapType.mjs';
export { addProgramDefines } from './rendering/renderers/gl/shader/program/preprocessors/addProgramDefines.mjs';
export { ensurePrecision } from './rendering/renderers/gl/shader/program/preprocessors/ensurePrecision.mjs';
export { insertVersion } from './rendering/renderers/gl/shader/program/preprocessors/insertVersion.mjs';
export { setProgramName } from './rendering/renderers/gl/shader/program/preprocessors/setProgramName.mjs';
export { stripVersion } from './rendering/renderers/gl/shader/program/preprocessors/stripVersion.mjs';
export { WGSL_TO_STD40_SIZE, createUboElementsSTD40 } from './rendering/renderers/gl/shader/utils/createUboElementsSTD40.mjs';
export { createUboSyncFunctionSTD40 } from './rendering/renderers/gl/shader/utils/createUboSyncSTD40.mjs';
export { generateArraySyncSTD40 } from './rendering/renderers/gl/shader/utils/generateArraySyncSTD40.mjs';
export { generateUniformsSync } from './rendering/renderers/gl/shader/utils/generateUniformsSync.mjs';
export { UNIFORM_TO_ARRAY_SETTERS, UNIFORM_TO_SINGLE_SETTERS } from './rendering/renderers/gl/shader/utils/generateUniformsSyncTypes.mjs';
export { GlStateSystem } from './rendering/renderers/gl/state/GlStateSystem.mjs';
export { mapWebGLBlendModesToPixi } from './rendering/renderers/gl/state/mapWebGLBlendModesToPixi.mjs';
export { GL_FORMATS, GL_TARGETS, GL_TYPES, GL_WRAP_MODES } from './rendering/renderers/gl/texture/const.mjs';
export { GlTexture } from './rendering/renderers/gl/texture/GlTexture.mjs';
export { GlTextureSystem } from './rendering/renderers/gl/texture/GlTextureSystem.mjs';
export { glUploadBufferImageResource } from './rendering/renderers/gl/texture/uploaders/glUploadBufferImageResource.mjs';
export { glUploadCompressedTextureResource } from './rendering/renderers/gl/texture/uploaders/glUploadCompressedTextureResource.mjs';
export { glUploadImageResource } from './rendering/renderers/gl/texture/uploaders/glUploadImageResource.mjs';
export { glUploadVideoResource } from './rendering/renderers/gl/texture/uploaders/glUploadVideoResource.mjs';
export { applyStyleParams } from './rendering/renderers/gl/texture/utils/applyStyleParams.mjs';
export { getSupportedGlCompressedTextureFormats } from './rendering/renderers/gl/texture/utils/getSupportedGlCompressedTextureFormats.mjs';
export { mapFormatToGlFormat } from './rendering/renderers/gl/texture/utils/mapFormatToGlFormat.mjs';
export { mapFormatToGlInternalFormat } from './rendering/renderers/gl/texture/utils/mapFormatToGlInternalFormat.mjs';
export { mapFormatToGlType } from './rendering/renderers/gl/texture/utils/mapFormatToGlType.mjs';
export { compareModeToGlCompare, mipmapScaleModeToGlFilter, scaleModeToGlFilter, wrapModeToGlAddress } from './rendering/renderers/gl/texture/utils/pixiToGlMaps.mjs';
export { unpremultiplyAlpha } from './rendering/renderers/gl/texture/utils/unpremultiplyAlpha.mjs';
export { WebGLRenderer } from './rendering/renderers/gl/WebGLRenderer.mjs';
export { BindGroupSystem } from './rendering/renderers/gpu/BindGroupSystem.mjs';
export { GpuBufferSystem } from './rendering/renderers/gpu/buffer/GpuBufferSystem.mjs';
export { GpuReadBuffer } from './rendering/renderers/gpu/buffer/GpuReadBuffer.mjs';
export { UboBatch } from './rendering/renderers/gpu/buffer/UboBatch.mjs';
export { GpuColorMaskSystem } from './rendering/renderers/gpu/GpuColorMaskSystem.mjs';
export { GpuDeviceSystem } from './rendering/renderers/gpu/GpuDeviceSystem.mjs';
export { GpuEncoderSystem } from './rendering/renderers/gpu/GpuEncoderSystem.mjs';
export { GpuStencilSystem } from './rendering/renderers/gpu/GpuStencilSystem.mjs';
export { GpuUboSystem } from './rendering/renderers/gpu/GpuUboSystem.mjs';
export { GpuUniformBatchPipe } from './rendering/renderers/gpu/GpuUniformBatchPipe.mjs';
export { PipelineSystem } from './rendering/renderers/gpu/pipeline/PipelineSystem.mjs';
export { calculateProjection } from './rendering/renderers/gpu/renderTarget/calculateProjection.mjs';
export { GpuRenderTarget } from './rendering/renderers/gpu/renderTarget/GpuRenderTarget.mjs';
export { GpuRenderTargetAdaptor } from './rendering/renderers/gpu/renderTarget/GpuRenderTargetAdaptor.mjs';
export { GpuRenderTargetSystem } from './rendering/renderers/gpu/renderTarget/GpuRenderTargetSystem.mjs';
export { BindGroup } from './rendering/renderers/gpu/shader/BindGroup.mjs';
export { GpuProgram } from './rendering/renderers/gpu/shader/GpuProgram.mjs';
export { GpuShaderSystem } from './rendering/renderers/gpu/shader/GpuShaderSystem.mjs';
export { WGSL_ALIGN_SIZE_DATA, createUboElementsWGSL } from './rendering/renderers/gpu/shader/utils/createUboElementsWGSL.mjs';
export { createUboSyncFunctionWGSL } from './rendering/renderers/gpu/shader/utils/createUboSyncFunctionWGSL.mjs';
export { extractAttributesFromGpuProgram } from './rendering/renderers/gpu/shader/utils/extractAttributesFromGpuProgram.mjs';
export { extractStructAndGroups } from './rendering/renderers/gpu/shader/utils/extractStructAndGroups.mjs';
export { generateArraySyncWGSL } from './rendering/renderers/gpu/shader/utils/generateArraySyncWGSL.mjs';
export { generateGpuLayoutGroups } from './rendering/renderers/gpu/shader/utils/generateGpuLayoutGroups.mjs';
export { generateLayoutHash } from './rendering/renderers/gpu/shader/utils/generateLayoutHash.mjs';
export { removeStructAndGroupDuplicates } from './rendering/renderers/gpu/shader/utils/removeStructAndGroupDuplicates.mjs';
export { GpuBlendModesToPixi } from './rendering/renderers/gpu/state/GpuBlendModesToPixi.mjs';
export { GpuStateSystem } from './rendering/renderers/gpu/state/GpuStateSystem.mjs';
export { GpuStencilModesToPixi } from './rendering/renderers/gpu/state/GpuStencilModesToPixi.mjs';
export { GpuTextureSystem } from './rendering/renderers/gpu/texture/GpuTextureSystem.mjs';
export { gpuUploadBufferImageResource } from './rendering/renderers/gpu/texture/uploaders/gpuUploadBufferImageResource.mjs';
export { blockDataMap, gpuUploadCompressedTextureResource } from './rendering/renderers/gpu/texture/uploaders/gpuUploadCompressedTextureResource.mjs';
export { gpuUploadImageResource } from './rendering/renderers/gpu/texture/uploaders/gpuUploadImageSource.mjs';
export { gpuUploadVideoResource } from './rendering/renderers/gpu/texture/uploaders/gpuUploadVideoSource.mjs';
export { getSupportedGPUCompressedTextureFormats } from './rendering/renderers/gpu/texture/utils/getSupportedGPUCompressedTextureFormats.mjs';
export { GpuMipmapGenerator } from './rendering/renderers/gpu/texture/utils/GpuMipmapGenerator.mjs';
export { WebGPURenderer } from './rendering/renderers/gpu/WebGPURenderer.mjs';
export { BackgroundSystem } from './rendering/renderers/shared/background/BackgroundSystem.mjs';
export { BlendModePipe } from './rendering/renderers/shared/blendModes/BlendModePipe.mjs';
export { Buffer } from './rendering/renderers/shared/buffer/Buffer.mjs';
export { BufferResource } from './rendering/renderers/shared/buffer/BufferResource.mjs';
export { BufferUsage } from './rendering/renderers/shared/buffer/const.mjs';
export { fastCopy } from './rendering/renderers/shared/buffer/utils/fastCopy.mjs';
export { ExtractSystem } from './rendering/renderers/shared/extract/ExtractSystem.mjs';
export { GenerateTextureSystem } from './rendering/renderers/shared/extract/GenerateTextureSystem.mjs';
export { DRAW_MODES } from './rendering/renderers/shared/geometry/const.mjs';
export { Geometry } from './rendering/renderers/shared/geometry/Geometry.mjs';
export { buildSimpleUvs, buildUvs } from './rendering/renderers/shared/geometry/utils/buildUvs.mjs';
export { ensureIsBuffer } from './rendering/renderers/shared/geometry/utils/ensureIsBuffer.mjs';
export { getAttributeInfoFromFormat } from './rendering/renderers/shared/geometry/utils/getAttributeInfoFromFormat.mjs';
export { getGeometryBounds } from './rendering/renderers/shared/geometry/utils/getGeometryBounds.mjs';
export { transformVertices } from './rendering/renderers/shared/geometry/utils/transformVertices.mjs';
export { InstructionSet } from './rendering/renderers/shared/instructions/InstructionSet.mjs';
export { GlobalUniformSystem } from './rendering/renderers/shared/renderTarget/GlobalUniformSystem.mjs';
export { isRenderingToScreen } from './rendering/renderers/shared/renderTarget/isRenderingToScreen.mjs';
export { RenderTarget } from './rendering/renderers/shared/renderTarget/RenderTarget.mjs';
export { RenderTargetSystem } from './rendering/renderers/shared/renderTarget/RenderTargetSystem.mjs';
export { viewportFromFrame } from './rendering/renderers/shared/renderTarget/viewportFromFrame.mjs';
export { ShaderStage } from './rendering/renderers/shared/shader/const.mjs';
export { Shader } from './rendering/renderers/shared/shader/Shader.mjs';
export { UboSystem } from './rendering/renderers/shared/shader/UboSystem.mjs';
export { UniformGroup } from './rendering/renderers/shared/shader/UniformGroup.mjs';
export { createUboSyncFunction } from './rendering/renderers/shared/shader/utils/createUboSyncFunction.mjs';
export { getDefaultUniformValue } from './rendering/renderers/shared/shader/utils/getDefaultUniformValue.mjs';
export { uboSyncFunctionsSTD40, uboSyncFunctionsWGSL } from './rendering/renderers/shared/shader/utils/uboSyncFunctions.mjs';
export { uniformParsers } from './rendering/renderers/shared/shader/utils/uniformParsers.mjs';
export { HelloSystem } from './rendering/renderers/shared/startup/HelloSystem.mjs';
export { BLEND_TO_NPM, STENCIL_MODES } from './rendering/renderers/shared/state/const.mjs';
export { getAdjustedBlendModeBlend } from './rendering/renderers/shared/state/getAdjustedBlendModeBlend.mjs';
export { State } from './rendering/renderers/shared/state/State.mjs';
export { AbstractRenderer } from './rendering/renderers/shared/system/AbstractRenderer.mjs';
export { SharedRenderPipes, SharedSystems } from './rendering/renderers/shared/system/SharedSystems.mjs';
export { SystemRunner } from './rendering/renderers/shared/system/SystemRunner.mjs';
export { CanvasPool, CanvasPoolClass } from './rendering/renderers/shared/texture/CanvasPool.mjs';
export { DEPRECATED_SCALE_MODES, DEPRECATED_WRAP_MODES, MSAA_QUALITY, SCALE_MODES, WRAP_MODES } from './rendering/renderers/shared/texture/const.mjs';
export { RenderTexture } from './rendering/renderers/shared/texture/RenderTexture.mjs';
export { BufferImageSource } from './rendering/renderers/shared/texture/sources/BufferSource.mjs';
export { CanvasSource } from './rendering/renderers/shared/texture/sources/CanvasSource.mjs';
export { CompressedSource } from './rendering/renderers/shared/texture/sources/CompressedSource.mjs';
export { ImageSource } from './rendering/renderers/shared/texture/sources/ImageSource.mjs';
export { TextureSource } from './rendering/renderers/shared/texture/sources/TextureSource.mjs';
export { VideoSource } from './rendering/renderers/shared/texture/sources/VideoSource.mjs';
export { Texture } from './rendering/renderers/shared/texture/Texture.mjs';
export { TextureGCSystem } from './rendering/renderers/shared/texture/TextureGCSystem.mjs';
export { TextureMatrix } from './rendering/renderers/shared/texture/TextureMatrix.mjs';
export { TexturePool, TexturePoolClass } from './rendering/renderers/shared/texture/TexturePool.mjs';
export { TextureStyle } from './rendering/renderers/shared/texture/TextureStyle.mjs';
export { TextureUvs } from './rendering/renderers/shared/texture/TextureUvs.mjs';
export { generateUID } from './rendering/renderers/shared/texture/utils/generateUID.mjs';
export { getCanvasTexture, hasCachedCanvasTexture } from './rendering/renderers/shared/texture/utils/getCanvasTexture.mjs';
export { getSupportedCompressedTextureFormats } from './rendering/renderers/shared/texture/utils/getSupportedCompressedTextureFormats.mjs';
export { getSupportedTextureFormats, nonCompressedFormats } from './rendering/renderers/shared/texture/utils/getSupportedTextureFormats.mjs';
export { autoDetectSource, resourceToTexture, textureFrom } from './rendering/renderers/shared/texture/utils/textureFrom.mjs';
export { createIdFromString } from './rendering/renderers/shared/utils/createIdFromString.mjs';
export { parseFunctionBody } from './rendering/renderers/shared/utils/parseFunctionBody.mjs';
export { ViewSystem } from './rendering/renderers/shared/view/ViewSystem.mjs';
export { RendererType } from './rendering/renderers/types.mjs';
export { Bounds } from './scene/container/bounds/Bounds.mjs';
export { _getGlobalBoundsRecursive, getFastGlobalBounds } from './scene/container/bounds/getFastGlobalBounds.mjs';
export { _getGlobalBounds, getGlobalBounds, updateTransformBackwards } from './scene/container/bounds/getGlobalBounds.mjs';
export { getLocalBounds, getParent } from './scene/container/bounds/getLocalBounds.mjs';
export { getGlobalRenderableBounds } from './scene/container/bounds/getRenderableBounds.mjs';
export { boundsPool, matrixPool } from './scene/container/bounds/utils/matrixAndBoundsPool.mjs';
export { childrenHelperMixin } from './scene/container/container-mixins/childrenHelperMixin.mjs';
export { effectsMixin } from './scene/container/container-mixins/effectsMixin.mjs';
export { findMixin } from './scene/container/container-mixins/findMixin.mjs';
export { measureMixin } from './scene/container/container-mixins/measureMixin.mjs';
export { onRenderMixin } from './scene/container/container-mixins/onRenderMixin.mjs';
export { sortMixin } from './scene/container/container-mixins/sortMixin.mjs';
export { toLocalGlobalMixin } from './scene/container/container-mixins/toLocalGlobalMixin.mjs';
export { Container, UPDATE_BLEND, UPDATE_COLOR, UPDATE_TRANSFORM, UPDATE_VISIBLE } from './scene/container/Container.mjs';
export { CustomRenderPipe } from './scene/container/CustomRenderPipe.mjs';
export { RenderContainer } from './scene/container/RenderContainer.mjs';
export { RenderGroup } from './scene/container/RenderGroup.mjs';
export { RenderGroupPipe } from './scene/container/RenderGroupPipe.mjs';
export { RenderGroupSystem } from './scene/container/RenderGroupSystem.mjs';
export { assignWithIgnore } from './scene/container/utils/assignWithIgnore.mjs';
export { buildInstructions, collectAllRenderables } from './scene/container/utils/buildInstructions.mjs';
export { checkChildrenDidChange } from './scene/container/utils/checkChildrenDidChange.mjs';
export { collectRenderGroups } from './scene/container/utils/collectRenderGroups.mjs';
export { definedProps } from './scene/container/utils/definedProps.mjs';
export { executeInstructions } from './scene/container/utils/executeInstructions.mjs';
export { mixColors, mixStandardAnd32BitColors } from './scene/container/utils/mixColors.mjs';
export { mixHexColors } from './scene/container/utils/mixHexColors.mjs';
export { multiplyHexColors } from './scene/container/utils/multiplyHexColors.mjs';
export { updateLocalTransform } from './scene/container/utils/updateLocalTransform.mjs';
export { updateRenderGroupTransform, updateRenderGroupTransforms, updateTransformAndChildren } from './scene/container/utils/updateRenderGroupTransforms.mjs';
export { updateWorldTransform } from './scene/container/utils/updateWorldTransform.mjs';
export { validateRenderables } from './scene/container/utils/validateRenderables.mjs';
export { GlGraphicsAdaptor } from './scene/graphics/gl/GlGraphicsAdaptor.mjs';
export { color32BitToUniform, colorToUniform } from './scene/graphics/gpu/colorToUniform.mjs';
export { GpuGraphicsAdaptor } from './scene/graphics/gpu/GpuGraphicsAdaptor.mjs';
export { BatchableGraphics } from './scene/graphics/shared/BatchableGraphics.mjs';
export { buildAdaptiveBezier } from './scene/graphics/shared/buildCommands/buildAdaptiveBezier.mjs';
export { buildAdaptiveQuadratic } from './scene/graphics/shared/buildCommands/buildAdaptiveQuadratic.mjs';
export { buildArc } from './scene/graphics/shared/buildCommands/buildArc.mjs';
export { buildArcTo } from './scene/graphics/shared/buildCommands/buildArcTo.mjs';
export { buildArcToSvg } from './scene/graphics/shared/buildCommands/buildArcToSvg.mjs';
export { buildCircle } from './scene/graphics/shared/buildCommands/buildCircle.mjs';
export { buildLine } from './scene/graphics/shared/buildCommands/buildLine.mjs';
export { buildPolygon } from './scene/graphics/shared/buildCommands/buildPolygon.mjs';
export { buildRectangle } from './scene/graphics/shared/buildCommands/buildRectangle.mjs';
export { buildTriangle } from './scene/graphics/shared/buildCommands/buildTriangle.mjs';
export { closePointEps, curveEps } from './scene/graphics/shared/const.mjs';
export { FillGradient } from './scene/graphics/shared/fill/FillGradient.mjs';
export { FillPattern } from './scene/graphics/shared/fill/FillPattern.mjs';
export { Graphics } from './scene/graphics/shared/Graphics.mjs';
export { GraphicsContext } from './scene/graphics/shared/GraphicsContext.mjs';
export { GpuGraphicsContext, GraphicsContextRenderData, GraphicsContextSystem } from './scene/graphics/shared/GraphicsContextSystem.mjs';
export { GraphicsPipe } from './scene/graphics/shared/GraphicsPipe.mjs';
export { GraphicsPath } from './scene/graphics/shared/path/GraphicsPath.mjs';
export { roundedShapeArc, roundedShapeQuadraticCurve } from './scene/graphics/shared/path/roundShape.mjs';
export { ShapePath } from './scene/graphics/shared/path/ShapePath.mjs';
export { SVGParser } from './scene/graphics/shared/svg/SVGParser.mjs';
export { SVGToGraphicsPath } from './scene/graphics/shared/svg/SVGToGraphicsPath.mjs';
export { buildContextBatches } from './scene/graphics/shared/utils/buildContextBatches.mjs';
export { buildGeometryFromPath } from './scene/graphics/shared/utils/buildGeometryFromPath.mjs';
export { convertFillInputToFillStyle } from './scene/graphics/shared/utils/convertFillInputToFillStyle.mjs';
export { getOrientationOfPoints } from './scene/graphics/shared/utils/getOrientationOfPoints.mjs';
export { triangulateWithHoles } from './scene/graphics/shared/utils/triangulateWithHoles.mjs';
export { MeshPlane } from './scene/mesh-plane/MeshPlane.mjs';
export { PlaneGeometry } from './scene/mesh-plane/PlaneGeometry.mjs';
export { MeshRope } from './scene/mesh-simple/MeshRope.mjs';
export { MeshSimple } from './scene/mesh-simple/MeshSimple.mjs';
export { RopeGeometry } from './scene/mesh-simple/RopeGeometry.mjs';
export { GlMeshAdaptor } from './scene/mesh/gl/GlMeshAdaptor.mjs';
export { GpuMeshAdapter } from './scene/mesh/gpu/GpuMeshAdapter.mjs';
export { BatchableMesh } from './scene/mesh/shared/BatchableMesh.mjs';
export { getTextureDefaultMatrix } from './scene/mesh/shared/getTextureDefaultMatrix.mjs';
export { Mesh } from './scene/mesh/shared/Mesh.mjs';
export { MeshGeometry } from './scene/mesh/shared/MeshGeometry.mjs';
export { MeshPipe } from './scene/mesh/shared/MeshPipe.mjs';
export { AnimatedSprite } from './scene/sprite-animated/AnimatedSprite.mjs';
export { NineSliceGeometry } from './scene/sprite-nine-slice/NineSliceGeometry.mjs';
export { NineSlicePlane, NineSliceSprite } from './scene/sprite-nine-slice/NineSliceSprite.mjs';
export { NineSliceSpritePipe } from './scene/sprite-nine-slice/NineSliceSpritePipe.mjs';
export { tilingBit, tilingBitGl } from './scene/sprite-tiling/shader/tilingBit.mjs';
export { TilingSpriteShader } from './scene/sprite-tiling/shader/TilingSpriteShader.mjs';
export { TilingSprite } from './scene/sprite-tiling/TilingSprite.mjs';
export { TilingSpritePipe } from './scene/sprite-tiling/TilingSpritePipe.mjs';
export { applyMatrix } from './scene/sprite-tiling/utils/applyMatrix.mjs';
export { QuadGeometry } from './scene/sprite-tiling/utils/QuadGeometry.mjs';
export { setPositions } from './scene/sprite-tiling/utils/setPositions.mjs';
export { setUvs } from './scene/sprite-tiling/utils/setUvs.mjs';
export { BatchableSprite } from './scene/sprite/BatchableSprite.mjs';
export { Sprite } from './scene/sprite/Sprite.mjs';
export { SpritePipe } from './scene/sprite/SpritePipe.mjs';
export { AbstractBitmapFont } from './scene/text-bitmap/AbstractBitmapFont.mjs';
export { bitmapFontTextParser } from './scene/text-bitmap/asset/bitmapFontTextParser.mjs';
export { bitmapFontXMLParser } from './scene/text-bitmap/asset/bitmapFontXMLParser.mjs';
export { bitmapFontXMLStringParser } from './scene/text-bitmap/asset/bitmapFontXMLStringParser.mjs';
export { bitmapFontCachePlugin, loadBitmapFont } from './scene/text-bitmap/asset/loadBitmapFont.mjs';
export { BitmapFont } from './scene/text-bitmap/BitmapFont.mjs';
export { BitmapFontManager } from './scene/text-bitmap/BitmapFontManager.mjs';
export { BitmapText } from './scene/text-bitmap/BitmapText.mjs';
export { BitmapTextPipe } from './scene/text-bitmap/BitmapTextPipe.mjs';
export { DynamicBitmapFont } from './scene/text-bitmap/DynamicBitmapFont.mjs';
export { getBitmapTextLayout } from './scene/text-bitmap/utils/getBitmapTextLayout.mjs';
export { resolveCharacters } from './scene/text-bitmap/utils/resolveCharacters.mjs';
export { HTMLText } from './scene/text-html/HTMLText.mjs';
export { HTMLTextPipe } from './scene/text-html/HTMLTextPipe.mjs';
export { HTMLTextRenderData, nssvg, nsxhtml } from './scene/text-html/HTMLTextRenderData.mjs';
export { HTMLTextStyle } from './scene/text-html/HtmlTextStyle.mjs';
export { HTMLTextSystem } from './scene/text-html/HTMLTextSystem.mjs';
export { extractFontFamilies } from './scene/text-html/utils/extractFontFamilies.mjs';
export { FontStylePromiseCache, getFontCss } from './scene/text-html/utils/getFontCss.mjs';
export { getSVGUrl } from './scene/text-html/utils/getSVGUrl.mjs';
export { getTemporaryCanvasFromImage } from './scene/text-html/utils/getTemporaryCanvasFromImage.mjs';
export { loadFontAsBase64 } from './scene/text-html/utils/loadFontAsBase64.mjs';
export { loadFontCSS } from './scene/text-html/utils/loadFontCSS.mjs';
export { loadSVGImage } from './scene/text-html/utils/loadSVGImage.mjs';
export { measureHtmlText } from './scene/text-html/utils/measureHtmlText.mjs';
export { textStyleToCSS } from './scene/text-html/utils/textStyleToCSS.mjs';
export { AbstractText, ensureOptions } from './scene/text/AbstractText.mjs';
export { CanvasTextMetrics } from './scene/text/canvas/CanvasTextMetrics.mjs';
export { CanvasTextPipe } from './scene/text/canvas/CanvasTextPipe.mjs';
export { CanvasTextSystem } from './scene/text/canvas/CanvasTextSystem.mjs';
export { fontStringFromTextStyle } from './scene/text/canvas/utils/fontStringFromTextStyle.mjs';
export { getCanvasFillStyle } from './scene/text/canvas/utils/getCanvasFillStyle.mjs';
export { SdfShader } from './scene/text/sdfShader/SdfShader.mjs';
export { localUniformMSDFBit, localUniformMSDFBitGl } from './scene/text/sdfShader/shader-bits/localUniformMSDFBit.mjs';
export { mSDFBit, mSDFBitGl } from './scene/text/sdfShader/shader-bits/mSDFBit.mjs';
export { Text } from './scene/text/Text.mjs';
export { TextStyle } from './scene/text/TextStyle.mjs';
export { ensureTextStyle } from './scene/text/utils/ensureTextStyle.mjs';
export { generateTextStyleKey } from './scene/text/utils/generateTextStyleKey.mjs';
export { getPo2TextureFromSource } from './scene/text/utils/getPo2TextureFromSource.mjs';
export { Spritesheet } from './spritesheet/Spritesheet.mjs';
export { spritesheetAsset } from './spritesheet/spritesheetAsset.mjs';
export { UPDATE_PRIORITY } from './ticker/const.mjs';
export { Ticker } from './ticker/Ticker.mjs';
export { TickerListener } from './ticker/TickerListener.mjs';
export { detectVideoAlphaMode } from './utils/browser/detectVideoAlphaMode.mjs';
export { isMobile } from './utils/browser/isMobile.mjs';
export { isSafari } from './utils/browser/isSafari.mjs';
export { isWebGLSupported } from './utils/browser/isWebGLSupported.mjs';
export { isWebGPUSupported } from './utils/browser/isWebGPUSupported.mjs';
export { unsafeEvalSupported } from './utils/browser/unsafeEvalSupported.mjs';
export { getCanvasBoundingBox } from './utils/canvas/getCanvasBoundingBox.mjs';
export { DATA_URI } from './utils/const.mjs';
export { default as EventEmitter } from 'eventemitter3';
export { removeItems } from './utils/data/removeItems.mjs';
export { resetUids, uid } from './utils/data/uid.mjs';
export { updateQuadBounds } from './utils/data/updateQuadBounds.mjs';
export { ViewableBuffer } from './utils/data/ViewableBuffer.mjs';
export { deprecation, v8_0_0 } from './utils/logging/deprecation.mjs';
export { logDebugTexture } from './utils/logging/logDebugTexture.mjs';
export { logRenderGroupScene, logScene } from './utils/logging/logScene.mjs';
export { warn } from './utils/logging/warn.mjs';
export { NOOP } from './utils/misc/NOOP.mjs';
export { Transform } from './utils/misc/Transform.mjs';
export { getResolutionOfUrl } from './utils/network/getResolutionOfUrl.mjs';
export { path } from './utils/path.mjs';
export { Pool } from './utils/pool/Pool.mjs';
export { BigPool, PoolGroupClass } from './utils/pool/PoolGroup.mjs';
export { VERSION, sayHello } from './utils/sayHello.mjs';
export { default as earcut } from 'earcut';

"use strict";
extensions.add(browserExt, webworkerExt);

export { browserExt, extensions, webworkerExt };

