import {Component, EventEmitter, Input, Output} from '@angular/core';
import {WebPackages} from '@core/models/purchase/web-packages';
import {NgIf} from '@angular/common';
import {ProductTypes} from '@core/models/purchase/product-types';

@Component({
    selector: 'app-purchase-card',
    standalone: true,
    imports: [NgIf],
    templateUrl: './purchase-card.component.html',
    styleUrl: './purchase-card.component.scss',
})
export class PurchaseCardComponent {
    @Input() data!: WebPackages;
    @Output() selectPackage: EventEmitter<number> = new EventEmitter<number>();
    protected readonly ProductTypes = ProductTypes;
    @Input() isSelect = false;

    onClickHandler() {
        this.selectPackage.emit(this.data.id);
    }
}
