import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CacheService {
    private cache: Map<string, {data: any; expiry: number}> = new Map();

    set(key: string, data: any, ttl: number) {
        const expiry = Date.now() + ttl;
        this.cache.set(key, {data, expiry});
    }

    get(key: string): any | null {
        const cached = this.cache.get(key);
        if (!cached) {
            return null;
        }
        if (cached.expiry < Date.now()) {
            this.clear(key);
            return null;
        }
        return cached.data;
    }

    clear(key: string) {
        this.cache.delete(key);
    }

    clearAll() {
        this.cache.clear();
    }
}
