import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@core/services';
import {environment} from '@env/environment';
import {WebPackages} from '@core/models/purchase/web-packages';
import {PurchaseFormResponse} from '@core/models/purchase/purchase-form-response';
import {Observable, of, tap} from 'rxjs';
import {CacheService} from '../cache/cache.service';

@Injectable({
    providedIn: 'root',
})
export class PurchaseService {
    http = inject(HttpClient);
    auth = inject(AuthService);
    cacheService = inject(CacheService);

    private purchaseBaseUrl: string;
    private customPurchaseBaseUrl: string;
    private cacheKey = 'web-packages';
    private cacheDuration = 1000 * 60 * 2;
    constructor() {
        this.purchaseBaseUrl = environment.apiUrl + '/web-purchase';
        this.customPurchaseBaseUrl = environment.apiUrl + '/custom-purchase';
    }

    getPackages(): Observable<WebPackages[]> {
        const userDetail = this.auth.userDetail;
        const userCountryCode = userDetail.country.iso2Code;
        const cachedData = this.cacheService.get(this.cacheKey);
        if (cachedData) {
            return of(cachedData);
        }
        return this.http
            .get<WebPackages[]>(`${this.purchaseBaseUrl}/web-country-package/countryCode=${userCountryCode}`)
            .pipe(
                tap(data => {
                    this.cacheService.set(this.cacheKey, data, this.cacheDuration);
                }),
            );
    }

    createFormUrl(packageId: string): Observable<PurchaseFormResponse> {
        const userSecretId = this.auth.userDetail.secretId;
        return this.http.post<PurchaseFormResponse>(
            `${this.customPurchaseBaseUrl}/create-form-url?userId=${userSecretId}`,
            {
                packageId,
                customPurchaseParameters: [{key: 'platform', value: 3}],
            },
        );
    }
}
