<!-- Main modal -->
<div class="relative max-w-[668px] px-2 mx-auto w-full max-h-full">
    <app-mt-modal-close-button></app-mt-modal-close-button>
    <!-- Modal content -->
    <div class="relative flex items-star justify-center flex-col bg-white rounded-lg shadow dark:bg-gray-700 p-10">
        <!-- Modal header -->
        <div class="flex rounded-t dark:border-gray-600">
            <h3 class="text-2xl font-semibold text-black dark:text-white">
                {{ constantService.i18n_constants.Buy_Crystal_Title | transloco }}
            </h3>
        </div>
        <!-- Modal body -->
        <div class="py-9">
            <div class="grid grid-cols-2 gap-6">
                <div
                    *ngFor="let skeletonPackage of skeletonPackages"
                    class="h-[52px] relative flex flex-wrap grow shrink items-center w-full max-w-[282px] bg-gray-200 dark:bg-gray-700 animate-pulse rounded-[8px]"
                ></div>

                <app-purchase-card
                    (selectPackage)="onSelectPackage($event)"
                    [isSelect]="selectedPackage === webPackage.id"
                    [data]="webPackage"
                    *ngFor="let webPackage of packages"
                ></app-purchase-card>
            </div>

            <div class="border-[1px] w-full h-0 mb-4 mt-4"></div>

            <!-- Modal footer -->
            <div class="flex w-full justify-end text-center">
                <button
                    (click)="onBuyButtonClick()"
                    class="rounded-lg w-32 h-10 bg-black text-white text-sm font-medium text-center hover:bg-primary-600 transition-colors duration-300 ease-in-out"
                >
                    Satın Al
                </button>
            </div>
        </div>
    </div>
</div>
